import $ from 'jquery';
import select2 from 'select2';

$(document).ready(function() {
  $('.add-select2').select2({
    tags: true,
    width: '100%' // need to override the changed default
  });

  $('body').on('click', '.flash-close-btn', function() {
    $(this).parent('.flash').remove();
  });

  var unsaved = false;

  $('.edit_device input').change(function(){ //triggers change in all input fields including text type
    unsaved = true;
  });

  $('input[type="submit"]').click(function() {
    unsaved = false
  });

  $('.header__search-input').select2({
    multiple: true,
    placeholder: '  Search',
    width: '90%',
    dropdownAutoWidth: true
  });

  $('.header__search-input').on('select2:select', function (e) {
    var url = '/products/' + e.params.data.id
    $('.select2-selection__choice').hide();
    window.location = url
  })

  function unloadPage(){ 
    if(unsaved){
      return "You have unsaved changes on this page. Do you want to leave this page and discard your changes or stay on this page?";
    }
  }

  window.onbeforeunload = unloadPage;
});
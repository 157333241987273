import $ from 'jquery';
import Swiper from 'swiper/bundle';

$(document).ready(function() {
  $('#product_id').change(function() {
    var slug = $(this).val();
    if (slug) {
      $('.product_preview_wrapper').hide();
      window.location.href = '/products/' + slug
    }
  });

  function resizePanels() {
    if ($('.weather_image_panel').length > 0 && $('.weather_image_panel').find('.image-right').height() > ($('.weather_image_panel').height() - 185)) {
      var height = (185 + $('.weather_image_panel').find('.image-right').height()) + 'px'
      $('.weather_image_panel').css('height', height)
      $('.other_modes_panel').css('height', height)
    }
  }
  resizePanels();

  $(window).on('orientationchange', function() {
    resizePanels();
  });

  var mySwiper = new Swiper('.swiper-weather-images', {
    // Optional parameters
    direction: 'horizontal',
    loop: true,
    slidesPerView: 1,
    effect: 'fade',
    autoplay: {
      delay: 3000
    },
    on: {
      slideChangeTransitionStart: function (ele) {
        var wrapper = $('.swiper-weather-images').closest('.swiper-group-wrapper')
        wrapper.find('.text_change').hide()
        wrapper.find(".text_change[data-text-change-order='" + ele.realIndex + "']").fadeIn(500)
      },
    },
  });

  var mySwiper = new Swiper('.swiper-mode-images', {
    // Optional parameters
    direction: 'horizontal',
    loop: true,
    slidesPerView: 1,
    effect: 'fade',
    autoplay: {
      delay: 3000
    },
    on: {
      slideChangeTransitionStart: function (ele) {
        var wrapper = $('.swiper-mode-images').closest('.swiper-group-wrapper')
        wrapper.find('.text_change').hide()
        wrapper.find(".text_change[data-text-change-order='" + ele.realIndex + "']").fadeIn(500)
      },
    },
  })
})
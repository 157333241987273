import $ from 'jquery';

$(document).ready(function() {
  $('input[type=radio][name="schedule_event[relative_to]"]').change(function() {  
    if (this.value == 'utc_midnight') {
      $('.schedule_event__change_time_utc_midnight').show()
      $('.schedule_event__change_time_sunrise_sunset').hide()
    } else if (this.value == 'local_sunrise') {
      $('.schedule_event__change_time_utc_midnight').hide()
      $('.schedule_event__change_time_sunrise_sunset').show()
      $('.sunrise_or_sunset').text('sunrise')
    } else if (this.value == 'local_sunset') {
      $('.schedule_event__change_time_utc_midnight').hide()
      $('.schedule_event__change_time_sunrise_sunset').show()
      $('.sunrise_or_sunset').text('sunset')
    }
  })

  $('#schedule_event_setting_type').change(function() {
    $('.schedule_event__nws_settings').hide()
    $('.schedule_event__draw_settings').hide()

    if ($(this).val() == 'NwsSetting') {
      $('.schedule_event__nws_settings').show()
    } else if ($(this).val() == 'DrawSetting') {
      $('.schedule_event__draw_settings').show()
    }
  });

  $('#date_hour, #date_minute').change(function() {
    var hours = $('#date_hour').val()
    var minutes = $('#date_minute').val()
    var utc_offset = $('#device_utc_offset').val()
    hours = (hours == '' ? 0 : parseInt(hours))
    minutes = (minutes == '' ? 0 : parseInt(minutes))

    updateChangeTime(hours, minutes, utc_offset)
  })

  $('#change_time_hours, #change_time_minutes, #before_after_sun').change(function() {
    var hours = $('#change_time_hours').val()
    var minutes = $('#change_time_minutes').val()
    var beforeAfter = $('#before_after_sun').val()
    hours = (hours == '' ? 0 : parseInt(hours))
    minutes = (minutes == '' ? 0 : parseInt(minutes))
    if (beforeAfter == 'before') {
      hours = -hours
      minutes = -minutes
    }
    updateChangeTime(hours, minutes, 0)
  })

  function updateChangeTime(hours, minutes, utc_offset) {
    var seconds = (hours * 3600 + minutes * 60 - utc_offset) % 86400
    $('#schedule_event_change_time').val(seconds)
  }
});
import $ from 'jquery';
import { showFlash } from './flashes.js'

$(document).ready(function() {
  var stripe = Stripe(process.env.STRIPE_PUBLISHABLE_KEY);
  var elements = stripe.elements();

  // Set up Stripe.js and Elements to use in checkout form
  var style = {
    base: {
      color: '#303238',
      fontSize: '16px',
      fontFamily: '"Open Sans", sans-serif',
      fontSmoothing: 'antialiased',
      '::placeholder': {
        color: '#CFD7DF',
      },
    },
    invalid: {
      color: '#e5424d',
      ':focus': {
        color: '#303238',
      },
    },
  };

  if ($('#card-element').length > 0) {
    var card = elements.create("card", { style: style });
    card.mount("#card-element");
  }

  var form = document.getElementById('payment-form');

  if (form) {
    form.addEventListener('submit', function(ev) {
      ev.preventDefault();
      var clientSecret = $('#submit').data('secret');
      var billingName = $('#full_name').val();
      var line1 = $('#line_1').val();
      var line2 = $('#line_2').val();
      var city = $('#city').val();
      var state = $('#state').val();

      $('.flash-close-btn').click();
      $('#submit').prop('disabled', true).html('<i class="fas fa-spinner fa-pulse"></i> Processing')

      stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: card,
          billing_details: {
            name: billingName,
            address: {
              line1: line1,
              line2: line2,
              city: city,
              state: state
            }
          }
        }
      }).then(function(result) {
        if (result.error) {
          // Show error to your customer (e.g., insufficient funds)
          $('#submit').prop('disabled', false).html('Pay Now')
          showFlash('alert', result.error.message)
        } else {
          // The payment has been processed!
          if (result.paymentIntent.status === 'succeeded') {
            $('#submit').prop('disabled', true).html('<i class="fa fa-check"></i> Success!')
            $('.shopping-cart-count').hide();
            $('.payment_info_wrapper').slideUp('slow', function() {
              showFlash('success', 'Payment Succeeded! You will receive an email confirmation with your order details soon.');
            })
          }
        }
      });
    });
  }
});
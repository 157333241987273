import $ from 'jquery';

$(document).ready(function() {
  $('#user-dropdown-link').click(function(e) {
    e.stopPropagation();
    e.preventDefault();
    $('.header__user-dropdown').toggle();
  });

  $('#min-menu-link').click(function(e) {
    e.stopPropagation();
    e.preventDefault();
    $('.min-menu-dropdown').toggle();
  });
});

$(document).click(function(){
  $('.header-dropdown').hide();
});

import $ from 'jquery';
import { showFlash } from './flashes.js'

$(document).ready(function() {
  $('.cart_product_quantity_field').change(function() {
    if ($(this).val() > 3) {
      showFlash('alert', 'There is a maximum of 3 per person at this time.')
    } else {
      $(this).closest('.edit_cart_product').submit();      
      $(this).attr('disabled', true);
    }
  });
});

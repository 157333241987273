import $ from 'jquery';

$(document).ready(function() {
  function initializeAutocomplete(id) {     
    var element = document.getElementById(id);
    if (element) {
      var autocomplete = new google.maps.places.Autocomplete(element, { types: ['geocode'] });
      google.maps.event.addListener(autocomplete, 'place_changed', onPlaceChanged);
    }
  }

  function onPlaceChanged() {
    var place = this.getPlace();

    // Clear previous address
    var addressFields = ['order_shipping_address_attributes_street_number',
                         'order_shipping_address_attributes_route',
                         'order_shipping_address_attributes_locality',
                         'order_shipping_address_attributes_administrative_area_level_1',
                         'order_shipping_address_attributes_administrative_area_level_2',
                         'order_shipping_address_attributes_administrative_area_level_3',
                         'order_shipping_address_attributes_country',
                         'order_shipping_address_attributes_postal_code',
                         'order_shipping_address_attributes_lat',
                         'order_shipping_address_attributes_lng'
                        ];
    for (var i in addressFields) {
      var type_element = document.getElementById(addressFields[i]);
      if (type_element) {
        type_element.value = ''
      }
    }

    var prefixes = ['order_shipping_address_attributes_']

    for (var i in place.address_components) {
      var component = place.address_components[i];
      for (var j in component.types) {  // Some types are ["country", "political"]
        for (var k in prefixes) {
          var type_element = document.getElementById(prefixes[k] + component.types[j]);
          if (type_element) {
            if (component.types.includes('administrative_area_level_1')) {
              var val = component.short_name
            } else {
              var val = component.long_name
            }
            type_element.value = val
          }
        }
      }
    }

    for (var i in prefixes) {
      var lat = $('#' + prefixes[i] + 'lat')
      if (lat.length > 0) { lat.val(place.geometry.location.lat()).trigger('change') }
      var lng = $('#' + prefixes[i] + 'lng')
      if (lng.length > 0) { lng.val(place.geometry.location.lng()).trigger('change') }
    }

    var streetNumber = $('#order_shipping_address_attributes_street_number').val();
    var route = $('#order_shipping_address_attributes_route').val();
    $('#order_shipping_address_attributes_line_1').val(streetNumber + ' ' + route);
  }

  google.maps.event.addDomListener(window, 'load', function() {
    initializeAutocomplete('order_shipping_address_attributes_line_1');   
  });
}); 

import Picker from 'vanilla-picker';
import $ from 'jquery';

$(document).ready(function() {
  $('.min_drawing').click(function() {
    $('.min_drawing').removeClass('selected');
    $(this).addClass('selected');
    $('#draw_setting_drawing_id').val($(this).data('drawing-id'));
    $('.edit_drawing_link').hide();
    $('.delete_frame_link').hide();
    $(this).parent('.min_drawing-wrapper').find('.edit_drawing_link').show();
    $(this).parent('.min_drawing-wrapper').find('.delete_frame_link').show();
  });

  $('.edit_drawing_link').each(function() {
  	var isCurrent = $(this).closest('.min_drawing-wrapper').find('.min_drawing').hasClass('selected');
  	if (isCurrent) {
  	  $(this).show();
  	}
  })
});
import $ from 'jquery';
import introJs from 'intro.js';

$(document).ready(function() {
  if (getQueryParams('tutorial', window.location.href) == "true") {
    startIntro()
  }

  $('.start_tutorial_btn').click(function() {
    startIntro();
  })

  function getQueryParams(params, url) {
    let href = url;
    //this expression is to get the query strings
    let reg = new RegExp( '[?&]' + params + '=([^&#]*)', 'i' );
    let queryString = reg.exec(href);
    return queryString ? queryString[1] : null;
  };

  function startIntro() {
    var hasSensor = $('.jumbotron').data('has-sensor')
    var modeText = hasSensor == true ? " When you gesture left/right in front of the sensor, you'll switch between these. You'll have to refresh the page to see the change here." : ''

    if ($('.jumbotron').data('current-mode-type') == 'NwsSetting') {
      introJs().setOptions({
        steps: [{
          element: document.querySelector('#nws_setting_prop'),
          intro: 'You can display live forecasts from the National Weather Service for ~1000 points across the state! Change the weather mode here.'
        }, {
          element: document.querySelector('.from_now_fields'),
          intro: "By default, the current forecast is displayed, but you can change it to show any time in the next 7 days. Your Firefly Map updates automatically, so if you set it to say, '2 days from now', that's what it will always show. This is specific to each preset, so you could show current temperature on one, but tomorrow's chance of rain on another."
        }, {
          element: document.querySelector('.setting_legend:not(.hide)'),
          intro: "But what do the colors mean? There's a legend here to tell you what value each color corresponds to. After a little while you won't need it though."
        }, {
          element: document.querySelector('.device_preview'),
          intro: "This preview shows you exactly what is on your Firefly Map. Occasionally there may be blank pixels when a weather station fails to report, butwe're working on a fix."
        }, {
          element: document.querySelector('.messages-dropdown-link'),
          intro: "You can find your way back to this page via this button, but we also recommend bookmarking this page or adding as shortcut on your smartphone homescreen."
        }]
      }).onexit(function() {
        history.pushState('End tutorial', "Device Modes", "?tutorial=false")
      }).start();
    } else if ($('.jumbotron').data('current-mode-type') == 'DrawSetting') {
      // introJs().setOptions({
      //   steps: [{
      //     element: document.querySelector
      //   }]
      // })
    } else {
      introJs().setOptions({
        steps: [{
          intro: "Your Firefly Map is ready! Here's a quick tutorial to show you how to use it!"
        }, {
          element: document.querySelector('#device_brightness'),
          intro: 'Just slide this bar to increase/decrease the screen brightness. All the way down is off.'
        }, {
          element: document.querySelector('.device-service-btns'),
          intro: "These buttons let you switch between your 6 presets (i.e. favorites), but don't click one just yet." + modeText
        }, {
          element: document.querySelector('#mode_setting_id'),
          intro: "Change the mode for each preset here. Current options are Weather, Elevation, Text, and Drawing with more coming soon! Again, don't change it yet."
        }, {
          element: document.querySelector('#text_setting_content'),
          intro: 'In text mode, you can write here and it will scroll on your Firefly Map. You can also change the speed, font, position, and color in the fields below. Come back after the tour and write something fun!'
        }, {
          element: document.querySelector('.device-service-btns'),
          intro: 'Great, now click on one of the weather presets to change the mode.'
        }]
      }).onexit(function() {
        history.pushState('End tutorial', "Device Modes", "?tutorial=false")
      }).start();
    }
  }
});


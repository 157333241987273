import Picker from 'vanilla-picker';
import $ from 'jquery';

$(document).ready(function() {
  window.drawHistory = []
  window.drawHistoryIndex = -1

	var colorPickerTarget = document.querySelector('#color_picker');
	var picker = new Picker({
		parent: colorPickerTarget,
		editorFormat: 'hsl',
		alpha: false,
		color: 'gold'
	});
  picker.onDone = function(color) {
		colorPickerTarget.style.background = color.rgbaString;
  }

  var colorPickerSettings = document.querySelector('#color_picker_text_setting');
  var currentSolidColor = $('#color_picker_text_setting').css('backgroundColor')
  var picker = new Picker({
    parent: colorPickerSettings,
    editorFormat: 'hsl',
    alpha: false,
    color: currentSolidColor,
    popup: 'top'
  });
  picker.onDone = function(color) {
    colorPickerSettings.style.background = color.rgbaString;
    $('#text_setting_text_color').val(color.hex)
  }

  $('input[type=radio][name=background_color]').change(function() {
    if (this.value == 'black') {
      var changeTo = '#000000'
    } else {
      var changeTo = '#ffffff'
    }
    changeBackgroundColor(changeTo);
  });

  $('.show_values_btn').click(function() {
    $('.led_value').toggle();
    turnBlurOn(false);
  });

  $('#add_frame').click(function() {
    $('#add_frame_options').toggle();
    $('.show_add_frame_options').toggle();
    $('.hide_add_frame_options').toggle();
  })

  $('#hide_add_frame_options').click(function() {
    $('#add_frame_options').toggle();
    $('#add_frame').toggle();
    $('#hide_add_frame_options').toggle();
  })

  $('input[type=checkbox][name=blur]').change(function() {
    if (this.checked) {
      turnBlurOn(true)
    } else {
      turnBlurOn(false)
    }
  });

  $('input[type=checkbox][name=overlay_map]').change(function() {
    if (this.checked) {
      $('.laser_image').show()
    } else {
      $('.laser_image').hide()
    }
  });

  $('#map_select').change(function() {
    var url = $(this).val()
    $('.laser_image').attr('src', url)
  });

  $('.live_updating_toggle-btn').click(function() {
    if ($(this).hasClass('fa-toggle-off')) {
      console.log('off')
      $('#live_updating').val(true);
      $(this).closest('form').submit();
    } else { 
      $('#live_updating').val(false);
      console.log('on')
    }
  })

  if ($('input[type=radio][name=background_color]:checked').val() == 'white') {
    changeBackgroundColor('#ffffff');    
  }
  if ($('#blur').is(':checked')) {
    turnBlurOn(true);
  }

  window.addEventListener("beforeunload", function(e) { 
    if(window.drawHistoryIndex > 0 && document.activeElement.name != 'commit') {
      e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
      // Chrome requires returnValue to be set
      e.returnValue = '';
      return "You have unsaved changes to your drawing. Do you want to leave this page and discard your changes or stay on this page?";
    }
  });

  function turnBlurOn(blur_on) {
    if (blur_on) {
      var blurAmount = ($('#device_preview td').width() / 2) + 1
      $('#device_preview td').css('filter', 'blur(' + blurAmount + 'px)')
    } else {
      $('#device_preview td').css('filter', 'blur(0px)')
    }    
  }

  function changeBackgroundColor(changeTo) {
    $('#device_preview td').each(function() {
      var backgroundColor = $(this).css('background-color');
      if (backgroundColor == 'rgb(0, 0, 0)' || backgroundColor == 'rgb(255, 255, 255)') {
        $(this).css('background-color', changeTo)
      }
    });    
  }

  function toggleRedoBtn() {
    if (window.drawHistoryIndex == window.drawHistory.length - 1) {
      $('#redo_btn').addClass('not_allowed'); // hide redo btn if at end of redo-ing. 
    } else {
      $('#redo_btn').removeClass('not_allowed');
    }
  }

  $('.led_pixel').mouseenter(function() {
  	if ($('.draw_canvas').data('down')) {
      if ($('.draw_tools').data('current-tool') == 'brush') {
        ledPixelHandler($(this));
      }
		}
  }).mousedown(function(e) {
    ledPixelHandler($(this));
  });

  function ledPixelHandler(ele) {
    $('#undo_btn').removeClass('not_allowed');
    $('#redo_btn').addClass('not_allowed');

    if ($('.draw_tools').data('current-tool') == 'brush') {
      var x = ele.data('x');
      var y = ele.data('y');
      var history = { action: 'draw',
                      x: x, 
                      y: y, 
                      from: ele.css('background-color'), 
                      to: currentColor() }
      addToHistory(history);
      drawPixel(x, y, currentColor());
    } else if ($('.draw_tools').data('current-tool') == 'dropper') {
      var color = ele.css('background-color')
      var from = $('#color_picker').css('background-color')
      var history = { action: 'pick_color',
                      from: from,
                      to: color }
      addToHistory(history);
      $('#color_picker').css('background-color', color)
    }
  }

  $('.draw_canvas').mousedown(function() {
    $(this).data('down', true)
	}).mouseup(function() {
    $(this).data('down', false)
    setFormData();
  });

  $('.selectable_tool').click(function() {
    $('.selectable_tool').removeClass('selected')
    $(this).addClass('selected')
    var tool = $(this).data('tool')
    $('.draw_tools').data('current-tool', tool)
  });

  $('#undo_btn').click(function() {
    var lastAction = window.drawHistory[window.drawHistoryIndex]
    if (lastAction) {
      window.drawHistoryIndex--;
      $('#redo_btn').removeClass('not_allowed');
      if (window.drawHistoryIndex == -1) {
        $('#undo_btn').addClass('not_allowed')
      }
      if (lastAction.action == 'draw') {
        drawPixel(lastAction.x, lastAction.y, lastAction.from); 
      } else if (lastAction.action == 'pick_color') {
        $('#color_picker').css('background-color', lastAction.from)
      }
    }
  });

  $('#redo_btn').click(function() {
    var nextAction = window.drawHistory[window.drawHistoryIndex + 1]
    if (nextAction) {
      if (nextAction.action == 'draw') {
        drawPixel(nextAction.x, nextAction.y, nextAction.to); 
      } else if (nextAction.action == 'pick_color') {
        $('#color_picker').css('background-color', nextAction.to)
      }
      window.drawHistoryIndex++;
      if (window.drawHistoryIndex == window.drawHistory.length - 1) {
        $('#redo_btn').addClass('not_allowed'); // hide redo btn if at end of redo-ing. 
      }
    }
  });

  function addToHistory(obj) {
    window.drawHistoryIndex++; // increment this first, just so we can use the value for setting array length
    window.drawHistory.length = window.drawHistoryIndex // Setting array length removes all future history, in the case of undo-ing then doing something different.
    window.drawHistory.push(obj);
    $('#undo_btn').removeClass('not_allowed');
  }

  function currentColor() { 
    return $('#color_picker').css('background-color')
  }

  function drawPixel(x, y, color) {
    var led = '.led_pixel[data-x="' + x + '"][data-y="' + y + '"]'
    $(led).css('background-color', color)
  }

  function setFormData() {
  	var result = []
  	var x, y;
  	for (y = 0; y < 32; y++) {
  		for (x = 0; x < 32; x++) {
  			var ele = $(".led_pixel[data-x='" + x + "'][data-y='" + y + "']")
  			var color = ele.css('background-color').replace(/[^\d,]/g, '').split(',') 
  			var hexStr = '#'
  			color.forEach(function(e) {
  				var rgb = intToHex(e)
  				hexStr += rgb
  			})
  			result.push([x, y, hexStr])
  		}
  	}
  	$('#frame_data').val(result)
  }

	function intToHex(rgb) { 
	  var hex = Number(rgb).toString(16);
	  if (hex.length < 2) {
	    hex = "0" + hex;
	  }
	  return hex;
	};
});

require("custom/carts")
require("custom/drawing")
require("custom/draw_setting")
require("custom/faq")
require("custom/fileUpload")
require("custom/flashes")
require("custom/general")
require("custom/google_places")
require("custom/header")
require("custom/home")
require("custom/intro")
require("custom/invitations")
require("custom/device_settings")
require("custom/payments")
require("custom/products")
require("custom/schedule_event")
require("custom/stripe")
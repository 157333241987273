import 'uppy/dist/uppy.min.css'

import {
  Core,
  Dashboard,
  Informer,
  ProgressBar,
  ThumbnailGenerator,
  AwsS3,
  AwsS3Multipart
} from 'uppy'

function fileUpload(fileInput) {
  const hiddenInput = document.querySelector('.upload-data'),
        imagePreview = document.querySelector('.upload-preview img'),
        dashboardWrapper = fileInput.parentNode.querySelector('.uppy-dashboard-wrapper')

  // remove our file input in favour of Uppy's
  fileInput.parentNode.removeChild(fileInput)

  const uppy = Core({
      autoProceed: true,
      maxNumberOfFiles: 1,
      restrictions: {
        allowedFileTypes: ['image/jpg', 'image/jpeg', 'image/png', 'image/gif']
      }
    })
    .use(Dashboard, {
      target: dashboardWrapper,
      inline: true,
      height: 300,
      locale: { strings: { addMoreFiles: 'Choose File', addMore: 'Choose File' } }
    })
    .use(ThumbnailGenerator, {
      thumbnailWidth: 600,
    })
    .use(AwsS3Multipart, {
      companionUrl: '/', // will call the presign endpoint on `/s3/params`
    })

  uppy.on('thumbnail:generated', (file, preview) => {
    // show preview of the image using URL from thumbnail generator
    imagePreview.src = preview
    document.querySelector('.upload-preview').classList.remove('hide')
  })

  // Remove previously uploaded files.
  uppy.on('file-added', (file) => {
    const items = uppy.getFiles().forEach(function(element) {
      if (file.id != element.id) {
        uppy.removeFile(element.id)
      }
    })
  })

  uppy.on('upload-success', (file, response) => {
    // construct uploaded file data in the format that Shrine expects
    const uploadedFileData = {
      id: response.uploadURL.match(/\/cache\/([^\?]+)/)[1], // extract key without prefix
      storage: 'cache',
      metadata: {
        size: file.size,
        filename: file.name,
        mime_type: file.type,
      }
    }
    // set hidden field value to the uploaded file data so that it's submitted
    // with the form as the attachment
    hiddenInput.value = JSON.stringify(uploadedFileData)
  })
}


function multipleFileUpload(fileInput) {
  const imagePreview = document.querySelector('.upload-preview img'),
        dashboardWrapper = fileInput.parentNode.querySelector('.uppy-dashboard-wrapper')

  // remove our file input in favour of Uppy's
  fileInput.parentNode.removeChild(fileInput)

  const uppy = Core({
      autoProceed: true,
      restrictions: {
        allowedFileTypes: ['image/jpg', 'image/jpeg', 'image/png', 'image/gif'],
        maxNumberOfFiles: 10,
      }
    })
    .use(Dashboard, {
      target: dashboardWrapper,
      inline: true,
      height: 300,
    })
    .use(ThumbnailGenerator, {
      thumbnailWidth: 600,
    })
    .use(AwsS3Multipart, {
      companionUrl: '/', // will call the presign endpoint on `/s3/params`
    })

  uppy.on('thumbnail:generated', (file, preview) => {
    // show preview of the image using URL from thumbnail generator
    imagePreview.src = preview
    document.querySelector('.upload-preview').classList.remove('hide')
  })

  uppy.on('upload-success', (file, response) => {
    // construct uploaded file data in the format that Shrine expects
    const uploadedFileData = {
      id: response.uploadURL.match(/\/cache\/([^\?]+)/)[1], // extract key without prefix
      storage: 'cache',
      metadata: {
        size: file.size,
        filename: file.name,
        mime_type: file.type,
      }
    }

    // set hidden field value to the uploaded file data so that it's submitted
    // with the form as the attachment
    var index = document.querySelectorAll('.product_attachment_field').length
    document.querySelector('.uppy-hidden-file-inputs').innerHTML += '<input class="product_attachment_field" type="hidden" name="product[attachments_attributes][' + index + '][file]" id="product_attachments_attributes_' + index + '_file">'
    document.getElementById('product_attachments_attributes_' + index + '_file').value = JSON.stringify(uploadedFileData)
  })
}

document.addEventListener('DOMContentLoaded', () => {
  document.querySelectorAll('.upload-file').forEach(fileInput => {
    fileUpload(fileInput)
  })
})

document.addEventListener('DOMContentLoaded', () => {
  document.querySelectorAll('.upload-multiple-files').forEach(fileInput => {
    multipleFileUpload(fileInput)
  })
})

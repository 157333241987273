import $ from 'jquery';

$(document).ready(function() {
  $('#use_shipping_address').change(function() {
    if (this.checked) {
      $('.billing_address_field').each(function() {
        var id = $(this).attr('id');
        var value = $('#shipping_address_' + id).val()
        $(this).val(value);
      });
    } else {
      $('.billing_address_field').val('');
    }
  })
})
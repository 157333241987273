// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")
require("jquery")
require('select2/src/scss/core.scss');
require("custom/custom")

import $ from 'jquery';
global.$ = jQuery;

import '@fortawesome/fontawesome-free/scss/fontawesome.scss';
import '@fortawesome/fontawesome-free/scss/regular.scss';
import '@fortawesome/fontawesome-free/scss/solid.scss';
import '@fortawesome/fontawesome-free/scss/brands.scss';
import Picker from 'vanilla-picker';
import 'swiper/swiper-bundle.css';
import 'intro.js';
import 'intro.js/introjs.css';
// import 'rangeslider.js';

// import 'slick-carousel';

require('@uppy/core/dist/style.css')
require('@uppy/dashboard/dist/style.css')


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import $ from 'jquery';
import 'rangeslider.js';

$(document).ready(function() {
  $('#device_brightness').rangeslider();

  $('.device-service-btn').click(function() {
    // hacky to make work for mode or settings.
  	var settingId = $(this).data('setting-id')
    $('.device_setting-fields').hide()
    $('.device-service-btn').removeClass('selected')
    $(this).addClass('selected').find('.device-service-btn-icon').html('<i class="fas fa-spinner fa-spin"></i>')
    $('#device-setting-' + settingId).submit()
  })

  $('#mode_setting_id').change(function() {
    $(this).closest('form').submit();
  })

  $('#device_brightness').change(function() {
    $('.save-brightness-btn').click();
  })

  $('#nws_setting_from_now_quantity').change(function() {
  	validateFromNow();
  })

  $('#nws_setting_from_now_type').change(function() {
    validateFromNow();
  })

  $('#nws_setting_prop').change(function() {
    var settingProp = $(this).val();
    $('.setting_legend').hide();
    $('#nws_' + settingProp + '_legend').show();
  });

  function validateFromNow() {
  	var fromNow = $('#nws_setting_from_now_quantity').val()
  	var fromNowType = $('#nws_setting_from_now_type').val()
  	switch(fromNowType) {
  	  case "minutes":
  	  	var validFromNow = fromNow <= 8640
  	  	break;
  	  case "hours":
  	  	var validFromNow = fromNow <= 144
  	  	break;
  	  case "days":
  	  	var validFromNow = fromNow <= 6
  	  	break;
  	}

  	if (validFromNow) {
  		$('#save-device-btn').attr('disabled', false)
  	} else {
  		$('#save-device-btn').attr('disabled', true)
  		alert("Invalid value. Must be 0 - 6 days (144 hours)")
  	}
  }
})
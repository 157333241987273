import $ from 'jquery';

$(document).ready(function() {
	$('.question').click(function() {
		if ($(this).find('.fa-chevron-right').length > 0) {
			$(this).find('.fa-chevron-right').replaceWith('<i class="fa fa-chevron-down"></i>');
		} else {
			console.log('do it')
			$(this).find('.fa-chevron-down').replaceWith('<i class="fa fa-chevron-right"></i>');
		}
		$(this).find('.answer').toggle();
	})
})
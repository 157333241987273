import $ from 'jquery';

$(document).ready(function() {
  $('.add_sensor_question').click(function() {
    $('.add_sensor_explanation').toggle();
  });

  $('#add_9960_sensor').change(function() {
    window.location.href = $('.link_to_opposite_9960_product').attr('href')
    $('.order_btn').addClass('disabled');
    if (this.checked) {
      // $('input[name="cart_product[product_id]"]').val($('#with_9960_sensor_product_id').val())
    } else {
      // $('input[name="cart_product[product_id]"]').val($('#without_9960_sensor_product_id').val())
    }
  });

  $('.product_attachment_preview').click(function() {
    var id = $(this).data('attachmentId')
    $('.product_attachment').hide();
    $('.product_attachment[data-attachment-id="' + id + '"]').show();
    $('.product_attachment_preview').removeClass('product_attachment_preview-selected');
    $(this).addClass('product_attachment_preview-selected');
  })
});